import WalletConnectProvider from '@walletconnect/web3-provider';
import React, { useState } from 'react';
import {ethers} from 'ethers';
import Chummy from './Chummy.json';
import './MyComponent.css';
const MyComponent = () => {

	const [defaultAccount, setDefaultAccount] = useState(null);
	const [errorMessage, setErrorMessage] = useState(null);
	const [connectText, setConnectText] = useState('Connect Your Wallet');
	const [statusText, setStatus] = useState('waiting');

	const [provider, setProvider] = useState(null);
	const [signer, setSigner] = useState(null);
	const [contract, setContract] = useState(null);

	const [price, setPrice] = useState(0.1);
	const [size, setSize] = useState("?");
	const [sold, setSold] = useState("?");
	const [maxMint, setMaxMint] = useState(10);

	const contractAddress = "0x75D6D90F712493B6d4198c3822713321973536d3";

	const connectWalletHandler = () => {
		if (window.ethereum) {
			window.ethereum.request({method: 'eth_requestAccounts'}).then(result => {
				askRightNetwork()
				accountChangedHandler(result[0], new ethers.providers.Web3Provider(window.ethereum));
			})
		} else {
			if ("walletconnect" in localStorage) {
				if (JSON.parse(localStorage['walletconnect'])['connected'] == true) {
					console.log("Connected wallet already");
					set_provide_walletconnect();
				}
			} else {
				setErrorMessage("If you would like to use Metamask, make sure it is installed first.");
				set_provide_walletconnect();
			}
		}
	}

	const askRightNetwork = () => {
		window.ethereum.request({
			method: "wallet_switchEthereumChain",
			params: [{
				chainId: "0x1" //mainnet	
			}]
		});
	}

	const accountChangedHandler = (newAccount, tmpProvider) => {
		tmpProvider.pollingInterval = 8500;
		console.log(tmpProvider)
		setDefaultAccount(newAccount);
		updateEthers(tmpProvider);
		setConnectText('Wallet Connected');
		document.getElementsByClassName("form")[0].style = "display: block;";
		document.getElementsByClassName("connect")[0].style = "display: none;";
		style_element();
	}
	function style_element() {
		eval(`(function( $ ) {

			$.fn.numberstyle = function(options) {
		
				/*
				 * Default settings
				 */
				var settings = $.extend({
					value: 0,
					step: undefined,
					min: undefined,
					max: undefined
				}, options );
		
				/*
				 * Init every element
				 */
				return this.each(function(i) {
						
			  /*
			   * Base options
			   */
			  var input = $(this);
				  
					/*
			   * Add new DOM
			   */
			  var container = document.createElement('div'),
				  btnAdd = document.createElement('div'),
				  btnRem = document.createElement('div'),
				  min = (settings.min) ? settings.min : input.attr('min'),
				  max = (settings.max) ? settings.max : input.attr('max'),
				  value = (settings.value) ? settings.value : parseFloat(input.val());
			  container.className = 'numberstyle-qty';
			  btnAdd.className = (max && value >= max ) ? 'qty-btn qty-add disabled' : 'qty-btn qty-add';
			  btnAdd.innerHTML = '+';
			  btnRem.className = (min && value <= min) ? 'qty-btn qty-rem disabled' : 'qty-btn qty-rem';
			  btnRem.innerHTML = '-';
			  input.wrap(container);
			  input.closest('.numberstyle-qty').prepend(btnRem).append(btnAdd);
		
			  /*
			   * Attach events
			   */
			  // use .off() to prevent triggering twice
			  $(document).off('click','.qty-btn').on('click','.qty-btn',function(e){
				
				var input = $(this).siblings('input'),
					sibBtn = $(this).siblings('.qty-btn'),
					step = (settings.step) ? parseFloat(settings.step) : parseFloat(input.attr('step')),
					min = (settings.min) ? settings.min : ( input.attr('min') ) ? input.attr('min') : undefined,
					max = (settings.max) ? settings.max : ( input.attr('max') ) ? input.attr('max') : undefined,
					oldValue = parseFloat(input.val()),
					newVal;
				
				//Add value
				if ( $(this).hasClass('qty-add') ) {   
				  
				  newVal = (oldValue >= max) ? oldValue : oldValue + step,
				  newVal = (newVal > max) ? max : newVal;
				  
				  if (newVal == max) {
					$(this).addClass('disabled');
				  }
				  sibBtn.removeClass('disabled');
				 
				//Remove value
				} else {
				  
				  newVal = (oldValue <= min) ? oldValue : oldValue - step,
				  newVal = (newVal < min) ? min : newVal; 
				  
				  if (newVal == min) {
					$(this).addClass('disabled');
				  }
				  sibBtn.removeClass('disabled');
				  
				}
				  
				//Update value
				input.val(newVal).trigger('change');
					
			  });
			  
			  input.on('change',function(){
				
				const val = parseFloat(input.val()),
					  min = (settings.min) ? settings.min : ( input.attr('min') ) ? input.attr('min') : undefined,
						max = (settings.max) ? settings.max : ( input.attr('max') ) ? input.attr('max') : undefined;
				
				if ( val > max ) {
				  input.val(max);   
				}
				
				if ( val < min ) {
				  input.val(min);
				}
			  });
			  
				});
			};
		
		  
		  /*
		   * Init
		   */
		  
			$('.numberstyle').numberstyle();
		
		}( jQuery ));
		
		`);
	}
	function check_mint(sz) {
		if (sz == 8000) {
			document.getElementsByClassName("mint")[0].disabled = false;
			document.getElementsByClassName("mint")[0].style.backgroundColor = "white";
			document.getElementsByClassName("mint")[0].innerText = "Mint";
		} else {
			document.getElementsByClassName("mint")[0].style.backgroundColor = "white";
			document.getElementsByClassName("mint")[0].innerText = "Mint";
			document.getElementsByClassName("mint")[0].disabled = false;
		}
	}
	async function updateEthers(tmpProvider) {
		let tempProvider = tmpProvider; //new ethers.providers.Web3Provider(window.ethereum);
		setProvider(tempProvider);

		let tempSigner = tempProvider.getSigner();
		setSigner(tempSigner);

		let tempContract = new ethers.Contract(
			contractAddress,
			Chummy.abi,
			tempSigner
		);
		setContract(tempContract);
		let pri = await tempContract.price();
		let stringPri = pri.toString();
		console.log(stringPri);
		setPrice(ethers.utils.formatEther(stringPri));
		let siz = await tempContract.maxSupply();
		setSize(siz.toNumber());
		let sol = await tempContract.currentSupply();
		setSold(sol.toNumber());
		let max = await tempContract.maxMint();
		setMaxMint(max.toNumber());
		if (max.toNumber() == 0) {
			console.log("max mint reached!");
			document.getElementsByClassName("mint")[0].outerHTML = '<p style="text-align: center; color: white;border: 1px solid white;padding: 10px;">Public-Sale has ended!</p>';
		} else {
			document.getElementsByClassName("mint")[0].disabled = true;
			document.getElementsByClassName("mint")[0].style.backgroundColor = "grey";
			document.getElementsByClassName("mint")[0].innerText = "Mint";
			check_mint();
		}
	}

	async function set_provide_walletconnect() {
		console.log("Using WConnect!!")
		const walletConnectProvider = new WalletConnectProvider({
			infuraId: "5a5c2c45eb2649c882058fc159fc0e13",
			chainId: 1,
			pollingInterval: 7500,
			qrcode: true
		  });
		  await walletConnectProvider.enable();
		  accountChangedHandler(JSON.parse(localStorage['walletconnect'])['accounts'][0], new ethers.providers.Web3Provider(walletConnectProvider));
	}
	async function Disconnect(event) {
		localStorage.clear();
		try {
			const permissions = await window.ethereum.request({
				method: 'wallet_requestPermissions',
				params: [{
				eth_accounts: {},
				}]
			});
		} catch(e) {
		}
		eval("location.reload();");
	}
	async function Mint(event) {
		event.preventDefault();
		let addr = event.target[0].value;
		let amnt = event.target[1].value;
		let pri = ethers.utils.parseEther(price);
		try {
			setStatus("mint")
			let tx = await contract.mint(addr.toString(), amnt.toString(), { value: (pri * amnt).toString(), gasLimit: (200000+150000*amnt).toString() });
			let receipt = await tx.wait();
			console.log(receipt); 
			setStatus("complete")
			eval('$.notify("You have successfully minted!", "success");')
			eval('$.notify("Check OpenSea to see what you got!", "success");')
			try {
				setSold(sold+1);
			} catch {
				eval('$.notify("Mint was successful! However, the webpage is not loading properly.", "success");')
			}
		} catch (err) {
			console.log(err);
			eval('$.notify("Transaction failed! Are you whitelisted?", "warn");')
			setStatus("failed")
		}
	}


	return (
		<div>
		  <div className="bg-black w-full pb-12 pt-10 text-center text-white rounded-lg justify-center items-center px-8 flex flex-col gap-2 snipcss-6wpUk">
		    <div id="container">
		      <span id="text1"></span>
		      <span id="text2"></span>
		    </div>
		    <svg id="filters">
		      <defs>
		        <filter id="threshold">
		          <feColorMatrix in="SourceGraphic" type="matrix" values="1 0 0 0 0
																0 1 0 0 0
																0 0 1 0 0
																0 0 0 255 -140" />
		        </filter>
		      </defs>
		    </svg>
		    <input id="etheraccount" type="hidden" value={defaultAccount}></input>
		    <input id="price" type="hidden" value={price}></input>
		    <input id="size" type="hidden" value={size}></input>
		    <input id="maxMint" type="hidden" value={maxMint}></input>
		    <input id="sold" type="hidden" value={sold}></input>
		    <input id="status" type="hidden" value={statusText}></input>
		    <div className="flex justify-between w-full uppercase px-4 text-sm tracking-widest">
		      <span> Price </span>
		      <span> total minted </span>
		    </div>
		    <div className="flex justify-between w-full font-bold px-4 text-xl">
		      <span> {price} </span>
		      <span> {sold} / 2500 </span>
		    </div>
		    <div className="h-full gap-4 w-full mt-9">
		      <button className="connect bg-white w-full text-black rounded-full px-8 py-3.5 transform duration-200 hover:scale-90 font-avenir-bold text-xl snip-button" onClick={connectWalletHandler} id="connectWallet"> {connectText} </button>
		      <div id="mintform">
				<form className="form" onSubmit={Mint}>
		          <input className="in" type="hidden" placeholder="Account" value={defaultAccount} />
		          <div>
		            <center className="center">
		                <input className="numberstyle" type="number" min="1" step="1" max="10" value="1" />
		            </center>
		          </div>
		          <br />
		          <button className="mint bg-white w-full text-black rounded-full px-8 py-3.5 transform duration-200 hover:scale-90 font-avenir-bold text-xl snip-button" type="submit">Refreshing..</button>
				  <div className="disconnect"><a href="#" onClick={Disconnect}>disconnect wallet</a></div>
				</form>
		      </div>
		    </div>
		  </div>
		</div>
	)
}

export default MyComponent;